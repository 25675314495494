/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

 .map-container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $color-secondary;

    .MuiFormControl-root {
        position: absolute;
        z-index: $nav-z-index;
        top: $small-gap;
        right: 50%;
        width: 450px;
        padding: 5px $small-gap;
        transform: translateX(50%);

        @include screen(mobile) {
            position: inherit;
            top: 0;
            width: 100%;
        }

        .MuiInputBase-root {
            background-color: #ffffff;
            border-radius: 20px;

            input {
                font-size: 13px;
                color: $color-dark-grey;
            }
        }

        .MuiInputAdornment-positionStart {
            margin-left: 5px;
        }

        svg {
            font-size: 18px;
            color: $color-secondary;
        }
    }
}

.custom-marker-label {
    position: relative;
    bottom: -30px;
    text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000, 2px 2px 0 #000000;
}

.special-marker-label {
    position: relative;
    bottom: 6px;
    font-weight: bold;
}

.cluster {
    div {
        top: 50%;
        margin-top: 2px;
        margin-left: 2px;
        transform: translateY(-50%);
    }
}

.mapDiv {
    width: 100%; 
    position: relative;
    margin-bottom: 40px;

    &__button{
        background-color: $color-primary;
        min-width: 40px; 
        height: 40px;
        width: auto;
        position: absolute; 
        z-index: $over-map-z-index; 
        margin-left: 10px; 
        margin-top: 10px;
        border: 0;
        border-radius: 5px;
        padding: 5px;
        display: grid;
        place-items: center;
    }

    &__overlay {
        background-color: rgba(0,0,0,0.7); 
        width: 100%;
        height: 100%; 
        position: absolute;
        display: grid;
        place-items: center;

        &--hidden {
            z-index: $hidden-z-index;
        }

        &--over-map {
            z-index: $over-map-z-index; 
        }
    }

    &__enableButton {
        display: inline-flex;
        color: #fff;
        text-transform: uppercase;
        font-family: $font-open-sans-semibold;
        font-size: 16px;
        padding: 0px 20px;
        gap: 10px;
        margin:0;
    }
    
}