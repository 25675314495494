/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.schedule-table-buttons {
    display: inline-flex;
    height: 48px;

    button {
        all: unset;
        cursor: pointer;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        font-size: 12px;
        padding: 10px;
        border: 1px solid $color-grey-D5D7DB;
        background-color: $color-white;
        color: $color-black;
        transition: all 0.3s ease-in-out;

        &.selected {
            background-color: $color-primary;
            color: $color-white;
        }

        &:last-of-type {
            border-radius: 0 8px 8px 0;
            border-left: none;
        }
    }
}

.schedule-days-select {
    width: 100%;
    font-family: $font-primary;
    display: inline-flex;
    margin-bottom: 15px;
    overflow-x: auto;

    &__day {
        all: unset;
        cursor: pointer;
        font-size: 14px;
        padding: 8px 0;
        border-bottom: 3px solid transparent;
        opacity: 0.6;
        color: $color-black;
        transition: all 0.4s ease-in-out;

        &.selected {
            opacity: 1;
            font-family: $font-open-sans-bold;
            border-bottom: 3px solid $color-black;
        }

        &:not(:last-of-type) {
            margin-right: 50px;
        }
    }
}

.schedule-table-days-label {
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
    font-family: $font-open-sans-bold;
    font-size: 14px;
    margin-bottom: 5px;

    button {
        all: unset;
        cursor: pointer;
        text-decoration: underline;
        margin-left: 4px;
        color: $color-primary;
    }
}

.schedule-table {
    font-family: $font-primary;
    font-size: 14px;
    display: grid;
    grid-template-columns: auto 1fr;
    width: 100%;
    overflow-x: auto;
    max-height: 480px;

    &__hours-container {
        display: flex;

        span {
            display: flex;
            min-width: 70px;
            flex: 1;
            align-items: center;
            padding-left: 2px;
            border: 1px solid rgba(224, 224, 224, 1);
        }
    }

    &__bollard-container {
        padding: 10px;
        border: 1px solid rgba(224, 224, 224, 1);

        &__schedule-container {
            display: flex;

            &__overlaps {
                display: flex;
                flex-direction: column;

                &__container {
                    display: flex;
                    flex: 1;
                    outline: 1px solid rgba(224, 224, 224, 1);
                    min-height: 10px;
                }
            }

            &__schedule {
                min-height: 15px;
                outline: 1px solid rgba(224, 224, 224, 1);

                &--unavailable {
                    background-color: $color-light-grey;
                    flex: 60;
                }

                &--available {
                    cursor: pointer;

                    &:hover {
                        background-color: rgba($color-green, 0.8);
                    }
                }

                &--reserved {
                    background-color: rgba(#ff0000, 0.5);
                }

                &--selected {
                    background-color: rgba($color-green, 0.8);
                }
            }
        }
    }
}

.bollard-table {
    &__grid {
        width: 100%;
    }
}