/*!
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

.reservation-manager-detail {
    padding: 0 32px;

    h2 {
        font-size: 24px;
        font-weight: bold;
    }

    .Mui-selected {
        background-color: #E4E7EC;
        border: 0;
        border-radius: 8px;
        padding: 14px 16px;
    }

    .MuiTab-wrapper {
        font-size: 16px;
        text-transform: none;
    }

    .MuiTabs-indicator {
        background-color: transparent;
    }

    .MuiTabPanel-root {
        padding: 55px 0 0 0;
    }

    &__header {
        display: flex;
        border-bottom: 1px solid rgba($color-grey-D5D7DB, 0.47);
        padding-bottom: 27px;
        margin-bottom: 24px;
        margin-top: 20px;

        &--title {
            color: $color-grey-667085;
        }

        button {
            &:hover {
                background-color: transparent;
            }
        }
    }

    &__tab-panel {
        .MuiPaper-elevation1 {
            box-shadow: none;
        }
    }

    &__add-area-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .MuiButton-root {
            background-color: $color-primary;
            color: $color-white;
            border-radius: 8px;
            padding: 5px 20px !important;
            font-size: 14px;
            font-weight: bold;

            &:hover {
                background-color: $color-primary;
            }
        }
    }

    &__businesses {
        border: 2px solid #D5D7DB;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 65px;
        padding: 10px;

        &--expanded {
            @extend .reservation-manager-detail__businesses;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &__collapsed-container {
            border: 2px solid #D5D7DB;
            border-top: none;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            margin-bottom: 10px;
            position: relative;
            max-height: 200px;
            overflow-y: auto;

            &--expanded {
                @extend .reservation-manager-detail__businesses__collapsed-container;
                border: none;
            }

            hr:last-of-type {
                display: none;
            }

            &__area-wrapper {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    padding-left: 20px;
                    font-weight: bold;
                }

                .MuiButton-root {
                    background-color: transparent;
                    border: 1px solid $color-error !important;
                    border-radius: 8px;
                    font-size: 12px;
                    color: $color-error !important;
                    height: 30px;
                    padding: 10px 5px !important;
                    box-shadow: none;
                    margin-right: 10px;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }

        &__right {
            display: flex;
            align-items: center;
        }
    }
}

.MuiMenuItem-root.menu-item-remove {
    color: $color-red;
}

.reservation-manager-menu {
    top: 50px !important;

    li {
        &:last-of-type {
            color: $color-error;
        }
    }
}