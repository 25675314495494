/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.auth-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    color: #ffffff;
    background-color: $color-primary;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-text-fill-color: white !important;
        transition: background-color 9999s ease-in-out 0s;
    }

    button {
        color: #ffffff;
    }

    &__gradient-container {
        flex-shrink: 0;
        width: 100%;
        height: 45vh;
        text-align: center;
        background: linear-gradient($color-primary, $color-secondary);

        &__padding-container {
            padding: 50px;

            a {
                color: #ffffff;
            }

            h2 {
                font-size: 24px;
                font-weight: bold;
                color: $color-secondary;
            }
        }
    }

    .register-form-grid {
        margin-top: $small-gap;
    }

    &__logo {
        margin: 50px 0 10px 0;
    }

    &__title {
        font-size: 24px;
        font-weight: bold;
        color: $color-secondary;
    }

    &__activation-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
            font-size: 75px;
        }

        &__success-image {
            color: $color-primary;
        }

        &__error-image {
            color: $color-error;
        }
    }

    .register-bottom {
        margin: $small-gap 0;
    }

    .MuiFormControl-root {
        margin-bottom: 10px;

        svg {
            color: $color-secondary;
        }

        .MuiInputBase-root {
            padding: 5px 20px;
            background-color: rgba(#ffffff, 0.4);
            border-radius: 10px;

            .MuiInputAdornment-positionStart {
                margin-left: -10px;
            }

            .MuiInputAdornment-positionEnd {
                margin-right: -15px;
            }

            svg {
                right: 10px;
            }

            .MuiAutocomplete-popupIndicator {
                margin-right: 15px;
            }

            input {
                font-size: 14px;
                color: #ffffff;

                &::placeholder,
                &::-moz-placeholder,
                &::-webkit-input-placeholder {
                    opacity: 1;
                }
            }

            &::before {
                display: none;
                border: none;
            }

            &::after {
                border: none;
            }
        }

        .MuiFormLabel-root.Mui-focused {
            color: rgba(0, 0, 0, 0.54);
        }
    }

    .login-image {
        margin-top: -130px;

        @include screen(mobile) {
            margin-top: -110px;
            width: 100%;
        }
    }

    .login-text {
        color: #ffffff;
        margin: $small-gap 0;
        font-size: 14px;
    }

    .login-inputs {
        justify-content: center;
    }

    .login-bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-top: 30px;
        margin-bottom: 10px;
        text-align: center;

        p {
            margin: 10px 0 0 0;
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
        }

        .white-button {
            margin-bottom: 25px;
        }

        &__recover {
            font-size: 11px;
            text-decoration: none;
            color: #ffffff;
            opacity: 0.59;
        }

        &__register {
            font-size: 14px;
            text-decoration: none;
            color: $color-secondary;
        }
    }

    .MuiSelect-root {
        color: #ffffff;
    }
}
