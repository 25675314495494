/*!
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

.bookings-form-container-new {
    padding: 0 25px 25px 25px;
}

.schedule-days-select {
    width: 100%;
    font-family: $font-primary;
    display: inline-flex;
    margin-bottom: 15px;
    overflow-x: auto;

    &__day {
        all: unset;
        border-bottom: 3px solid transparent;
        transition: all 0.4s ease-in-out;
        border: 1px solid $color-grey-D5D7DB;
        border-radius: 8px;
        display: flex;
        align-items: center;
        min-width: 150px;

        button {
            color: $color-black;
            font-size: 14px;
            font-weight: 600;
            border: none;
            background-color: transparent;
            padding: 12px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:nth-child(2n) {
                padding: 5px;
            }

            svg {
                fill: $color-black;
                width: 15px;
            }
        }

        &.selected {
            opacity: 1;
            font-family: $font-open-sans-bold;
            background-color: $color-black;

            button {
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }

        &:not(:last-of-type) {
            margin-right: 8px;
        }

        &:hover {
            border: 1px solid $color-black;
        }
    }

    &__btn-picker {
        text-align: center;
        min-width: 45px;
        height: 45px;
        border: none;
        border-radius: 8px;
        background-color: $color-grey-E4E7EC;
        margin-right: 14px;

        .MuiIconButton-root, svg {
            margin-right: 0 !important;
        }

        .MuiIconButton-root {
            width: 100%;
            height: 100%;
            margin-right: 0 !important;
        }
    }
}

.schedule-table-days-label {
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
    font-family: $font-open-sans-bold;
    font-size: 14px;
    margin-bottom: 5px;

    button {
        all: unset;
        cursor: pointer;
        text-decoration: underline;
        margin-left: 4px;
        color: $color-primary;
    }
}

.schedule-table {
    font-family: $font-primary;
    font-size: 14px;
    display: grid;
    grid-template-columns: auto 1fr;
    width: 100%;
    overflow-x: auto;
    max-height: 480px;

    &__hours-container {
        display: flex;

        span {
            display: flex;
            min-width: 70px;
            flex: 1;
            align-items: center;
            justify-content: center;
            padding-left: 2px;
            border: 1px solid rgba(224, 224, 224, 1);
            font-weight: 600;
        }
    }

    &__bollard-container {
        padding: 10px;
        border: 1px solid rgba(224, 224, 224, 1);
        font-weight: 600;
        text-align: center;

        &__schedule-container {
            display: flex;
            outline: 1px solid rgba(224, 224, 224, 1);

            &__overlaps {
                display: flex;
                flex-direction: column;

                &__container {
                    &__wrapper {
                        display: flex;
                        flex: 1;
                        min-height: 10px;

                        &--has-padding {
                            padding: 3px;
                        }
                    }
                }
            }

            &__wrapper {
                flex: 60;

                &--has-padding {
                    padding: 3px;
                    height: 100%;
                }
            }

            &__schedule {
                min-height: 15px;
                outline: 1px solid rgba(224, 224, 224, 1);
                height: 100%;
                font-size: 12px;
                font-weight: bold;
                display: flex;
                align-items: center;
                width: 100%;

                &--unavailable {
                    background-color: $color-grey-98A2B3;
                    border-radius: 4px;
                }

                &--available {
                    padding-left: 3px;
                    font-size: 12px;
                    font-weight: bold;
                    color: $color-primary;
                    border: 1px dashed $color-primary;
                    border-radius: 4px;
                    cursor: pointer;

                    &:hover {
                        background-color: $color-primary-lightest;
                    }
                }

                &--reserved {
                    background-color: $color-red-E13238;
                    color: $color-white;
                    padding-left: 3px;
                    border-radius: 4px;
                }

                &--selected {
                    background-color: $color-primary;
                    color: $color-white;
                    padding-left: 3px;
                    border-radius: 4px;
                }
            }
        }
    }
}

.widest-form-new {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__grid-container, &__form-control {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-left: 61px;

        &--table-section {
            margin-bottom: 0;

            &__bollard-table {
                margin-bottom: 30px;
            }
        }

        &.last {
            border-bottom: none;
        }

        &.bollard-table {
            grid-template-columns: repeat(1, 1fr);

            .bollard-table__grid {
                margin-bottom: 30px;
            }
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .MuiFormControl-root:first-of-type {
            bottom: 0;
        }
    }

    &__section-header {
        font-size: 18px;
        color: $color-dark-blue-101828;
        font-weight: bold;
    }

    .MuiSvgIcon-root {
        fill: $color-dark-blue-101828;
        margin-right: 6px;
    }

    .MuiPaper-elevation1 {
        box-shadow: none;
        width: 100%;
        border-bottom: 1px solid $color-grey-E8EAED;
        margin-bottom: 35px;
    }

    .MuiCardHeader-root {
        display: flex;
        flex-direction: row-reverse;
        padding: 0;
    }

    .MuiCardHeader-action {
        margin-top: 0;
        margin-right: 0;
        align-self: center;
    }

    .MuiIconButton-root {
        padding: 0 ;
        margin-right: 30px;

        &:hover {
            background-color: transparent;
        }
    }

    .MuiFormControl-root {
        width: 100%;
        margin-bottom: 38px
    }

    .MuiInput-root {
        padding: 5px 15px;
    }

    .MuiCheckbox-root {
        margin-left: 10px;
        margin-right: 10px;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .MuiInput-underline:before {
        border-bottom: 0;
    }

    .MuiInput-underline:before {
        content: none;
    }

    .MuiSelect-select:focus {
        background-color: transparent;
    }

    .MuiCardContent-root:last-child {
        padding: 0 0 24px 0;
    }

    .input-container {
        .MuiInput-root, .MuiOutlinedInput-multiline {
            background: $color-grey-F8F9FA;
            border-radius: 8px;
            margin-top: 7px;
            border: none;
            box-shadow: none;

            &::after {
                border-bottom: none;
                transition: none;
            }
        }

        label {
            position: relative;
            transform: none;
            font-size: 14px ;
            color: $color-black-222222;
            font-weight: 600;
        }
    }

    &--calendar {
        height: 100%;
    }

    &--booking {
        .mapDiv {
            margin-bottom: 0;
        }

        .booking-observations-field {
            width: 100%;
            margin-top: 15px;

            .MuiOutlinedInput-notchedOutline {
                border-color: rgba(0, 0, 0, 0.42);
            }
        }
    }

    &__grid-container {
        grid-column-gap: $small-gap;
        width: 100%;
        margin-bottom: -20px;

        .MuiFormControl-marginNormal {
            bottom: 5px;
        }
    }

    &__header-collapse {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        button {
            border: none;
            border-radius: 8px;
            padding: 14px 28px;
            font-weight: 600;
            cursor: pointer;
            font-size: 14px;
        }
    }
}

.placeholder-no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $color-grey-D5D7DB;
    border-radius: 8px;
    width: 100%;

    &__title {
        font-size: 24px;
        font-weight: 600;
        margin: 16px 0;
    }

    &__message {
        font-size: 16px;
        color: $color-grey-667085;
        margin: auto;
        margin-bottom: 80px;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    border-bottom: 1px solid $color-grey-E8EAED;
    position: sticky;
    z-index: 1;
    top: 0;
    width: 100%;
    background-color: $color-white;
    padding: 23.5px 0;

    @include screen(mobile) {
        top: 64px;
    }

    &__left {
        display: flex;
        align-items: center;
        padding-right: 20px;

        &__header {
            margin-left: 35px;
            font-size: 18px;
        }

        a {
            align-self: normal;
            position: unset !important;
            margin-left: 0;
        }

        .return-link {
            display: flex;
            font-size: 1.5em;
            color: $color-black;
            align-self: flex-start;
            padding: 14px 35px 12px 0;
        }
    }

    &__right {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__price {
            margin-right: 150px;
            @include screen(mobile) {
                margin-right: 50px;
            }

            h1 {
                font-size: 14px;
                color: $color-grey-667085;
                margin-bottom: -15px;
            }

            p {
                font-size: 18px;
                font-weight: bold;
                color: $color-black-222222;
            }
        }

        &__btn-submit {
            padding-left: 0px;

            .MuiButton-root {
                margin: 0 !important;
                font-size: 14px !important;
                width: auto;
                height: 40px;
                border-radius: 8px;

                &:disabled {
                    background-color: $color-grey-98A2B3 !important;
                    color: $color-grey-667085;
                    cursor: not-allowed;
                }
            }
        }
    }
}


