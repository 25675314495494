/*!
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

.booking-calendar {
    width: 100%;
    flex: 1 1 auto;
    max-height: 60vh;

    &__top {
        width: 100%;
        display: inline-flex;
        padding-bottom: 10px;
        margin-bottom: 20px;

        &__items {
            display: inline-flex;
            align-items: flex-end;
            flex: 1 0;
            overflow-x: hidden;
            padding-right: 20px;

            &__item {
                &:not(:last-of-type) {
                    padding-right: 30px;
                }

                > button {
                    white-space: nowrap;
                    border: none;
                    background-color: unset;
                    padding: 0;
                    cursor: pointer;
                    border-bottom: 2px solid transparent;
                    height: 25px;
                    min-width: 70px;
                    font-family: $font-primary;

                    &.selected {
                        font-weight: bold;
                        border-bottom: 2px solid $color-black;
                    }
                }

                > div {
                    height: 30px;
                    display: inline-flex;
                    align-items: center;

                    h3, h4 {
                        font-size: 14px;
                    }

                    h4 {
                        margin-left: 4px;
                        font-family: $font-open-sans-semibold;
                        opacity: 0.6;
                    }
                }
            }
        }

        &__controls {
            border-left: 1px solid rgba($color-black, 0.5);
            width: 120px;
            flex: 0 0 auto;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            button {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                border: 1px solid $color-black;
                cursor: pointer;

                 svg {
                     width: 15px;
                     height: 15px;
                 }
            }
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: auto 1fr;
        max-height: min(calc(100% - 90px), 550px);
        overflow: auto;
        position: relative;
        grid-gap: 1px;
        border: 1px solid $color-light-grey;
        background-color: $color-light-grey;

        &__description {
            padding: 8px 12px;
            position: sticky;
            top: 0;
            background-color: #ffffff;
            z-index: 1;

            @include screen(mobile) {
                top: 64px;
            }
        }

        &__bollard-name {
            padding: 8px 12px;
            z-index: 0;
            background-color: $color-white;
        }

        &__row {
            display: inline-flex;
            position: sticky;
            top: 0;
            background-color: $color-light-grey;
            z-index: 1;
            gap: 1px;

            &:first-of-type {
                border-left: none;

                div:first-of-type {
                    border-top: none;
                }
            }

            &--bi-weekly {
                z-index: 0;

                > span {
                    flex: 60 1;
                }
            }

            &--individual-availability {
                z-index: 0;
                position: relative;

                span.transparent {
                    background-color: transparent;
                }
            }

            &__occupation-wrapper {
                position: absolute;
                inset: 0 0 0 0;
                display: inline-flex;

                span:not(:last-child) {
                    border-right: 1px solid $color-light-grey;
                }
            }

            span {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                color: rgba($color-black, 0.8);
                background-color: $color-white;

                &.red-cell {
                    cursor: pointer;
                    background-color: $color-red-calendar;
                }

                &.yellow-cell {
                    cursor: pointer;
                    background-color: $color-yellow-calendar;
                }

                &.grey-cell {
                    background-color: $color-grey-calendar;
                }
            }

            &__date {
                flex: 1 1;
                min-width: 70px;
                padding: 8px 15px;
                font-family: $font-primary;
                font-size: 14px;
                white-space: nowrap;
            }

            @include screen(mobile) {
                top: 64px;
            }
        }
    }
}
