/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.drawer {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding: 32px 30px;
    overflow-x: initial;
    overflow-y: auto;
    transition: width 0.3s linear;
    background-color: $color-primary;

    @include screen(mobile) {
        width: 0;
        padding: 0;
        border: none;
    }

    hr {
        border-top: 1.5px solid $color-green-6BC5AC;
        margin: 10px 0;
    }

    .user-info {
        display: flex;
        align-items: center;

        &__name-wrapper {
            display: flex;
            flex-direction: column;

            p {
                margin: 0;
            }

            p:first-of-type {
                font-weight: bold;
            }
        }

        img {
            width: 45px;
            height: 45px;
            margin-right: $small-gap;
            border-radius: 8px;
        }

        @include screen(mobile) {
            display: none;
        }
    }

    &--active {
        background-color: $color-green-6BC5AC;
        border-radius: 6px;
    }

    &__top-container {
        display: flex;
        flex-direction: column;
    }

    &__bottom-container {
        display: flex;
        flex-direction: column;
        grid-gap: $small-gap;
        justify-content: space-between;
        height: 100%;

        @include screen(mobile) {
            display: none;
        }

        .MuiInputBase-root {
            color: $color-white;
            padding-left: 5px;

            svg {
                fill: $color-white;
            }

            fieldset {
                border: none;
            }
        }

        &__select-language {
            cursor: pointer !important;
            padding: 10px;
        
            .MuiSelect-select:focus {
                border-radius: unset !important; 
                background-color: unset !important;
            }
            
            &:hover,
            &--active {
                border-radius: 6px !important;
                @extend .drawer--active;
            }
        
            &__menu {
                .MuiPaper-root {
                    border-radius: 4px !important;
        
                    .MuiList-padding {
                        padding: 6px !important;
                    }
        
                    .MuiListItem-root {
                        border-radius: 2px !important;
                        padding-left: 8px !important;
                    
                        &:not(:last-of-type) {
                            margin-bottom: 4px;
                        }
                    }
                }
            }
        }
    }

    &--no-hover {
        opacity: 0.5;

        &:hover {
            background-color: transparent !important;
            cursor: default;
        }
    }

    a {
        display: flex;
        align-items: center;
        margin: 3px 0;
        text-decoration: none;
        white-space: nowrap;
        color: $color-white;
        padding: 3px 5px;

        &:hover {
            background-color: $color-green-6BC5AC;
            border-radius: 6px;
        }

        @include screen(mobile) {
            display: none;
        }
    }

    .active {
        margin-bottom: 32px;
    }

    &--no-hover {
        display: flex;
        align-items: center;
        margin: 5px 0;
        text-decoration: none;
        white-space: nowrap;
        color: $color-white;
        padding: 8px 5px;

        &:hover {
            background-color: $color-green-6BC5AC;
            border-radius: 6px;
        }

        @include screen(mobile) {
            display: none;
        }
    }

    img {
        margin-right: 5px;
    }

    &__mobile-toggle {
        display: none !important;
        position: absolute;
        z-index: $menu-z-index;
        top: $small-gap;
        left: $small-gap;
        cursor: pointer;

        @include screen(mobile) {
            display: block !important;
        }
    }

    .hidden-button {
        display: flex;
        align-items: center;
        padding: 0;
        font-size: 16px;
        color: $color-white;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-family: $font-primary;
    }

    &--expanded {
        padding: $small-gap;
        @include screen(mobile) {
            width: 100%;
        }

        .drawer__mobile-toggle {
            position: relative;
            top: 0;
            left: 0;
            margin-bottom: $small-gap;
        }

        .user-info {
            display: flex;
        }

        .drawer__bottom-container {
            display: flex;
        }

        a {
            display: flex;
        }
    }

    &__merchant-title {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-white;
        margin: 0;
    }

    &__search {
        z-index: 5;
    }

    &__search-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-white;
        padding: 0px 5px 0px 10px;
        border-radius: 6px;
        height: 35px;
        width: 280px;
        margin: 5px 0;
        cursor: pointer;

        &__selected {
            display: inline-flex;
            align-items: flex-end;
            max-width: calc(100% - 55px);

            span:first-of-type {
                font-size: 14px;
                flex: 0 0 auto;
                margin-right: 8px;
            }

            span:last-of-type {
                font-size: 12px;
                opacity: 0.6;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__popper {
            z-index: $search-popper-z-index;
            background-color: $color-white;
            border-radius: 6px;
            width: 280px;
            max-height: 300px;
            padding: 10px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            margin-top: 5px;

            &.drawer--not-expanded {
                @include screen(mobile) {
                    display: none;
                }
            }

            &__input-wrapper {
                display: flex;
                align-items: center;
                gap: 5px;
                background-color: $color-white-F2F4F7;
                padding: 8px 5px 8px 10px;
                border-radius: 6px;
                height: 52px;
                width: 100%;
            }

            &__list-wrapper {
                max-height: 200px;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 10px;

                .MuiList-root {
                    height: 100%;
                    max-height: 300px;
                    padding: 10px;
                    overflow-y: auto;

                    &::-webkit-scrollbar-track {
                        background-color: #F4F4F4;
                    }

                    &::-webkit-scrollbar {
                        width: 6px;
                        background: #F4F4F4;

                    }

                    &::-webkit-scrollbar-thumb {
                        background: #c2c2c2;
                        border-radius: 15px;
                    }

                    .MuiListItem-root {
                        cursor: pointer;
                        padding-bottom: 0;

                        &:hover {
                            background-color: $color-white-F2F4F7;
                            border-radius: 15px;
                        }
                    }
                }
            }
        }
    }

    .MuiOutlinedInput-input {
        padding: 0;
    }
}

.language-overlay {
    position: absolute;
    top: $small-gap;
    right: $small-gap;
    width: 100%;
    max-width: 150px;

    label,
    .MuiInput-input,
    svg {
        color: #ffffff !important;
    }

    .MuiFormControl-root {
        .MuiInputBase-root {
            padding: 5px 20px;
            background-color: rgba(#ffffff, 0.4);
            border-radius: 10px;

            .MuiInputAdornment-positionStart {
                margin-left: -10px;
            }

            .MuiInputAdornment-positionEnd {
                margin-right: -15px;
            }

            svg {
                right: 10px;
            }

            .MuiAutocomplete-popupIndicator {
                margin-right: 15px;
            }

            input {
                font-size: 14px;
                color: #ffffff;

                &::placeholder,
                &::-moz-placeholder,
                &::-webkit-input-placeholder {
                    opacity: 1;
                }
            }

            &::before {
                display: none;
                border: none;
            }

            &::after {
                border: none;
            }
        }

        .MuiFormLabel-root.Mui-focused {
            color: rgba(0, 0, 0, 0.54);
        }
    }

    @include screen(mobile) {
        display: none;
    }
}

