/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.root {
    height: 100vh;

    .app {
        height: 100vh;

        .app-router {
            display: flex;
            height: 100%;

            .app-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                overflow: auto;
            }
        }
    }
}

.table-container {
    width: 100%;
}
