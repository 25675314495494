/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.narrow-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 350px;
    height: 100%;
}

.wide-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    height: 100%;

    &__grid-container {
        display: grid;
        grid-column-gap: $small-gap;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        width: 100%;
        margin-bottom: $small-gap;

        @include screen(mobile) {
            display: flex;
            flex-direction: column;
        }

        .MuiFormControl-root {
            justify-content: flex-end;
        }

        &--margin-top {
            margin-top: 20px;
        }

        &__double-space {
            grid-column-end: span 2;

            @include screen(mobile) {
                width: 100%;
            }

            &--details {
                grid-column-end: span 2;
                display: flex;
                flex-direction: column;
                gap: 2px;

                @include screen(mobile) {
                    width: 100%;
                }
            }
        }

        &__section-tooltip {
            margin-top: 10px;
            font-size: 14px;
            opacity: 0.7;
        }

        &__double-input {
            display: grid;
            grid-column-gap: $small-gap;
            grid-template-columns: auto 1fr;
            width: 100%;

            .MuiFormControl-root {
                &:first-of-type {
                    bottom: 3px;
                }
            }
        }

        &__terms-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            @include screen(mobile) {
                display: inline-block;
            }

            span {
                &:first-of-type {
                    @include screen(mobile) {
                        height: 22px;
                        vertical-align: middle;
                    }
                }
            }

            .MuiFormControl-root {
                bottom: -2px;
                width: auto;
            }

            .MuiFormControlLabel-root {
                margin-right: 0;
            }

            &__terms-link {
                display: inline-block;
                margin: 0;
                padding: 5px;
                font-size: 15px;
                text-decoration: underline;
                background-color: transparent;
                border: none;
                cursor: pointer;
                color: #ffffff;
            }

            &--margin-top {
                margin-top: $small-gap;
            }
        }
    }

    &__wider-grid-container {
        display: grid;
        grid-column-gap: $small-gap;
        grid-template-columns: repeat(5, 1fr) auto;
        align-items: center;
        width: 100%;

        @include screen(mobile) {
            display: flex;
            flex-direction: column;

            svg {
                align-self: flex-end;
            }

            .MuiFormControl-root {
                width: 100% !important;
            }
        }

        &__separator {
            grid-column-end: span 6;
            width: 100%;
            height: 1px;
            margin: $small-gap 0;
            background-color: $color-light-grey;
        }

        .MuiFormControl-marginNormal {
            bottom: 5px;
        }

        svg {
            &:hover {
                color: red;
                cursor: pointer;
            }
        }
    }

    .add-button {
        @include screen(mobile) {
            align-self: center !important;
        }
    }

    &__checkbox-container {
        display: grid;
        grid-column-gap: $small-gap;
        grid-template-columns: repeat(2, auto);
        align-self: start;
    }

    &__area-picture-container {
        display: flex;
        align-items: center;
        width: 100%;

        @include screen(mobile) {
            flex-direction: column;
        }

        img {
            height: 150px;
            margin-right: $small-gap;

            @include screen(mobile) {
                margin-right: 0;
                margin-bottom: $small-gap;
            }
        }

        p {
            margin-bottom: 0;
            color: $color-dark-grey;
        }
    }
}

.widest-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &--calendar {
        height: 100%;
    }

    &--booking {
        .mapDiv {
            margin-bottom: 0;
        }

        .booking-observations-field {
            width: 100%;
            margin-top: 15px;

            .MuiOutlinedInput-notchedOutline {
                border-color: rgba(0, 0, 0, 0.42);
            }
        }
    }

    &__grid-container {
        display: grid;
        grid-column-gap: $small-gap;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        width: 100%;
        margin-bottom: $small-gap;

        .MuiFormControl-marginNormal {
            bottom: 5px;
        }

        @include screen(tablet) {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 20px;
        }
    }
}

.section-header {
    width: 100%;
    display: flex;
    grid-column-end: span 2;
    align-items: center;
    align-self: flex-start;
    font-weight: 600;
    color: $color-blue;

    &:not(:first-child) {
        margin-top: $small-gap;
    }

    svg {
        margin-right: 10px;
    }

    &--margin {
        margin-bottom: $small-gap;
    }

    &--spaced {
        justify-content: space-between;
    }

    > span {
        display: inline-flex;
        align-items: center;
    }
}

.custom-material-input {
    position: relative;
    width: 100%;
    margin-top: $small-gap;
    margin-bottom: 5px;

    * {
        width: 100%;
    }

    input {
        &::placeholder {
            color: transparent;
        }
    }

    label {
        position: absolute;
        top: 10px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.54);
        pointer-events: none;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    .google-autocomplete {
        display: block;
        padding: 10px 10px 5px 0;
        font-size: 16px;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.54);

        &:focus {
            outline: none;
        }

        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
            top: -10px;
            font-size: 12px;
        }

        &:focus ~ label {
            color: #3f51b5;
        }

        &:focus ~ .bar {
            &::before,
            &::after {
                width: 50%;
            }
        }
    }

    .bar {
        display: block;
        position: relative;
        bottom: -1px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            bottom: 1px;
            width: 0;
            height: 2px;
            background: #3f51b5;
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;
        }

        &::before {
            left: 50%;
        }

        &::after {
            right: 50%;
        }
    }

    &--errors {
        label {
            color: red !important;
        }

        .google-autocomplete {
            border-bottom: 2px solid red;
        }

        .bar {
            &::before,
            &::after {
                background: red !important;
            }
        }
    }
}

.margin-button {
    margin: $small-gap !important;
}

.country-autocomplete {
    position: relative;
    bottom: 5px;

    @include screen(mobile) {
        width: 100%;
    }
}

.form-tooltip {
    align-self: start;
    margin-left: 30px;
    opacity: 0.7;
}

.form-section-title {
    align-self: start;
    margin: $small-gap 0;
    font-weight: 600;
}

.Mui-error {
    svg {
        color: #f44336;
        opacity: 0.7;
    }
}

.add-schedule-container {
    width: 100%;

    @include screen(mobile) {
        text-align: center;
    }
}

.file-input-wrapper {
    position: relative;

    &__input {
        position: absolute;
        opacity: 0;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        font-size: 16px;
        color: #ffffff;
        background-color: #3f51b5;
        border-radius: 4px;
        cursor: pointer;

        svg {
            margin-right: 10px;
        }
    }

    p {
        @include screen(mobile) {
            text-align: center;
        }
    }
}

.form-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    .form-section-title {
        margin: auto 0;
    }

    @include screen(mobile) {
        flex-direction: column;
        align-items: flex-start;

        .form-section-title {
            margin: 20px 0;
        }
    }
}