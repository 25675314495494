/*!
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

.confirm {

    .MuiPaper-root {
        border-radius: 8px;
    }

    &__content {
        display: flex;
        min-width: 350px;
        max-width: 500px;
        flex-direction: column;

        &.large {
            min-width: 600px;
        }

        textarea {
            height: 150px !important;
        }

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            &__title {
                font-weight: bold;
                font-size: 18px;
                color: $color-black;
            }

            svg {
                font-size: 22px;
                color: $color-black;
            }
        }

        &__bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            text-align: center;

            &__message {
                width: 100%;
                margin-bottom: 20px;
                font-family: $color-black;
                font-size: 14px;
                color: $color-black;
                text-align: justify;
            }

            &__buttons {
                display: flex;
                gap: 10px;
                margin-bottom: 30px;

                button {
                    padding: 5px 20px !important;

                    &:first-of-type {
                        border: 1px solid;
                    }
                }

                .delete {
                    background-color: $color-error;
                    color: $color-white;

                    &:hover {
                        background-color: $color-error;
                    }
                }

                .edit {
                    background-color: $color-primary;
                    color: $color-white;

                    &:hover {
                        background-color: $color-primary;
                    }
                }
            }
        }
    }
}

.delete-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;

    svg {
        width: 42px;
        height: 42px;
        margin-bottom: 3px;
    }

    p {
        font-family: $color-black;
        font-size: 14px;
        color: $color-white;
    }
}
