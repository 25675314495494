/*!
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

.map-modal {
    .MuiPaper-root {
        border-radius: 8px;
    }

    &__content {
        display: flex;
        width: 600px;
        flex-direction: column;

        &.MuiDialogContent-root {
            overflow: hidden;
        }

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            &__title {
                font-weight: bold;
                font-size: 18px;
                color: $color-error;
            }

            svg {
                font-size: 22px;
                color: $color-black;
            }
        }

        &__bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            text-align: center;

            &__map {
                width: 100%;
            }
        }
    }
}
