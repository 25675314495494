/**
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

.add-area-modal {
    .MuiPaper-root {
        border-radius: 8px;
        min-width: 600px;
        max-height: 800px;
        padding: 20px 30px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: center;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
        font-weight: bold;

        .MuiIconButton-root {
            &:hover {
                background-color: transparent;
            }

            .MuiIconButton-label {
                > svg {
                    height: 30px;
                    color: $color-black;
                }
            }
        }

        &__header {
            .MuiIconButton-root {
                padding-left: 0;
            }

            svg {
                width: 19px;
                margin-right: 13px;
            }
        }
    }

    &__input-wrapper {
        background-color: $color-white-F2F4F7;
        padding: 8px 5px 8px 10px;
        border-radius: 6px;
        box-shadow: 0px 0px 10px -4px $color-grey-667085;
        height: 52px;
        width: 100%;

        &__input {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        height: 400px;
        overflow-y: auto;
        padding-right: 10px;

        &__item-wrapper {
            padding-top: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;

            &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 40px;
                padding: 10px 0;

                &--remove {
                    @extend .add-area-modal__content__item-wrapper__item;

                    .MuiButton-root {
                        background-color: transparent !important;
                        border: 1px solid $color-error !important;
                        border-radius: 8px;
                        font-size: 12px;
                        color: $color-error !important;
                        height: 30px;
                        padding: 10px 5px !important;
                        box-shadow: none;

                        &:hover {
                            background-color: transparent;
                        }
                    }
                }

                .MuiButton-root {
                    background-color: transparent !important;
                    border: 1px solid $color-black;
                    border-radius: 8px;
                    font-size: 12px;
                    color: $color-black;
                    height: 30px;
                    padding: 10px 5px !important;
                    box-shadow: none;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}