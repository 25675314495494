/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

// BASE
@import 'base/mixins';
@import 'base/fonts';
@import 'base/variables';
@import 'base/base';
@import 'base/layout';

// VIEWS
@import 'components/views/drawer';
@import 'components/views/navbar';
@import 'components/views/form';
@import 'components/views/header';
@import 'components/views/booking_form';
@import 'components/views/booking_form_new';
@import 'components/views/booking_details';

// SCREENS
@import 'components/screens/map';
@import 'components/screens/profile';
@import 'components/screens/auth';
@import 'components/screens/tableScreens';
@import 'components/screens/formScreens';
@import 'components/screens/dashboard';
@import 'src/assets/styles/components/screens/reservationManagerDetails';

// ELEMENTS
@import 'components/elements/booking_calendar';
@import 'components/elements/notification';
@import 'components/elements/menu';
@import 'components/elements/loader';
@import 'components/elements/buttons';
@import 'components/elements/calendar';
@import 'components/elements/reservation_details_modal';
@import 'components/elements/new_invitation_modal';
@import 'src/assets/styles/components/elements/add_area_modal';
@import 'src/assets/styles/components/elements/confirm';
@import 'src/assets/styles/components/elements/map_modal';
