/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.businesses-form-container,
.bookings-form-container,
.merchants-form-container,
.areas-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 25px 25px 25px;

    @include screen(mobile) {
        padding: $small-gap;
    }

    &--header-container {
        display: flex;
        border-bottom: 1px solid $color-grey-E8EAED;
        width: 100%;
        background-color: $color-white;
        padding: 32px 0;

        .MuiIconButton-root {
            display: flex;
            font-size: 1.5em;
            color: $color-black;
            align-self: flex-start;
            padding: 14px 35px 12px 0;

            &:hover {
                background-color: transparent !important;
            }
        }

        &__header {
            font-size: 18px;

            &--title{
                color: $color-grey-667085;
            }
        }
    }
}

.areas-form-container {
    .map-container {
        margin-bottom: $small-gap;
    }

    .add-button {
        align-self: flex-start;
    }
}
