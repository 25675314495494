/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

// colors
$color-primary: #3EA08E;
$color-primary-lightest: #D8EDE5;
$color-secondary: #f7cf78;

$color-light-grey: #cccccc;
$color-error: #ff4e00;
$color-red: #D55757;
$color-red-calendar: #E56565;
$color-red-E13238: #E13238;
$color-red-AF272B: #AF272B;
$color-red-FFD8D9: #FFD8D9;
$color-red-ff0000: #ff0000;
$color-yellow-calendar: #E5E565;
$color-success: #51dfa2;
$color-green: #6FBB83;
$color-green-dark: #63AE77;
$color-green-D1EECC: #D1EECC;
$color-green-009300: #009300;
$color-green-6BC5AC: #6BC5AC;
$color-error-bg: #ffe0e0;
$color-blue: #4c9aff;
$color-dark-blue-101828: #101828;
$color-white: #FFFFFF;
$color-black: #000000;
$color-black-222222: #222222;
$color-black-1D2939: #1D2939;
$color-white-F2F4F7: #F2F4F7;
$color-grey-calendar: #e3e3e3;
$color-grey-E8EAED: #E8EAED;
$color-grey-667085: #667085;
$color-grey-98A2B3: #98A2B3;
$color-grey-D5D7DB: #D5D7DB;
$color-grey-767A80: #767A80;
$color-grey-F8F9FA: #F8F9FA;
$color-grey-E4E7EC: #E4E7EC;
$color-grey-E0E0E0: #E0E0E0;
$color-light-grey: #f0f0f0;
$color-dark-grey: #777777;
$color-grey: #999999;
$color-orange: #FBE1CC;
$color-brown: #662C00;

// fonts
$font-titles: 'CreatoDisplayBold', sans-serif;
$font-primary: 'CreatoDisplayRegular', sans-serif;
$font-open-sans-light: 'CreatoDisplayLight', sans-serif;
$font-open-sans-bold: 'CreatoDisplayBold', sans-serif;
$font-open-sans-semibold: 'CreatoDisplayMedium', sans-serif;

// sizes
$small-gap: 20px;
$medium-gap: 50px;
$large-gap: 100px;
$nav-height: 60px;

// z-indexes
$hidden-z-index: -1;
$over-map-z-index: 1;
$counter-z-index: 2;
$nav-z-index: 3;
$menu-z-index: 4;
$search-popper-z-index: 5;
