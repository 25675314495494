/*!
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

.new-invitation-modal {
    .MuiPaper-root {
        background-color: $color-white;
        max-height: 500px;
        width: 100%;
        max-width: 600px;
        border-radius: 18px;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 40px;
        margin: 40px 0 40px 0;

        h2 {
            font-weight: bold;
            font-size: 24px;
        }

        button {
            &:hover {
                background-color: transparent;
            }
            padding: 0;
        }

        svg {
            color: $color-black;
        }
    }

    &__content {
        padding: 0 40px;
        height: calc(100% - 140px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &__input-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 20px;

            &__label {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                font-size: 16px;

                svg {
                    color: $color-secondary;
                }

                span {
                    margin-left: 10px;
                    color: $color-primary;
                }
            }

            &__input {
                width: 100%;
                margin-right: 10px;

                .MuiFormControl-marginNormal {
                    margin-top: unset;
                }
            }

            .MuiInputBase-root {
                border-radius: 8px;
                border: 1px solid #D5D7DB;
                padding: 0 12px;
                font-size: 14px;
                font-weight: 600;
                height: 56px;
            }

            .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before {
                border-bottom: none;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 70px;
        padding: 0 40px;
        margin-bottom: 40px;

        button {
            padding: 5px 20px !important;

            &:first-of-type {
                background-color: $color-grey-E4E7EC;
                margin-right: 8px;
            }

            &[type="submit"] {
                background-color: $color-primary;
                color: $color-white;
            }
        }
    }
}
