/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.dashboard-page {
    width: 100%;
    padding: $medium-gap;

    @include screen(mobile) {
        padding: $small-gap;
    }

    h2 {
        font-weight: bold;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include screen(mobile) {
            flex-direction: column;
        }
    }
}
