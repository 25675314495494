/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

body {
    margin: 0;
    font-family: $font-primary;
}

.options-icon {
    cursor: pointer;
}

// ERRORS

.field-error {
    font-size: 11px;
    line-height: 11px;
    color: #f44336;
}

// LINKS

.return-link {
    display: flex;
    align-items: center;
    align-self: flex-start;
    text-decoration: none;
    color: #000000;
}

// ALIGNMENT

.align-start {
    align-self: flex-start;
}

.price-tag {
    padding: 2px 10px;
    background-color: rgba($color-grey, 0.6);
    border-radius: 40px;
    display: inline-flex;
    color: $color-white;

    &.booking-paid {
        background-color: $color-green-dark;
    }

    &.booking-not-paid {
        background-color: $color-red;
    }
}

.MuiBackdrop-root {
    z-index: 3000 !important;

    ~ .MuiDialog-container .MuiDialog-paper {
        z-index: 3050 !important;
    }
}

.MuiTableCell-root, .MuiButton-root, .MuiSelect-root, .MuiInputBase-input, .MuiFormLabel-root, .MuiTypography-body1,
.MuiTypography-body2, .MuiTableSortLabel-root, .MuiMenuItem-root, .MuiFormHelperText-root, .MuiTab-root {
    font-family: $font-primary !important;
}

.MuiButton-containedPrimary {
    background-color: $color-primary !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}
