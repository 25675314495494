/*!
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

.booking-details-container {
    display: flex;
    width: 100%;

    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

    &__content {
        width: 70%;
        border-right: 1px solid $color-grey-E8EAED;
        padding: 0 60px;

        @media only screen and (max-width: 1100px) {
            border-right: none;
        }

        &__section {
            border-bottom: 1px solid $color-grey-E8EAED;

            &__title {
                margin: 32px 0 32px 0;
                font-size: 18px;
                font-weight: 600;
            }

            &__row {
                margin-bottom: 32px;

                &__data {
                    display: flex;

                    &__icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-width: 40px;
                        height: 40px;
                        background-color: $color-primary-lightest;
                        border-radius: 8px;
                        margin-right: 20px;

                        svg {
                            width: 16px;
                            fill: $color-primary;
                        }
                    }

                    &__info {
                        p {
                            font-size: 14px;
                            font-weight: 600;
                            margin: 0;
                        }

                        &__title {
                            color: $color-grey-667085;
                        }

                        &__date-row {
                            display: flex;
                            margin-bottom: 10px;
                            padding-bottom: 10px;
                            border-bottom: 1px solid $color-grey-E8EAED;
                            font-size: 14px;
                            font-weight: 600;
                            width: 535px;

                            &__date {
                                width: 150px;
                            }

                            &__time {
                                width: 210px;
                            }

                            &__bollard {
                                width: 120px;
                            }

                            &__price {
                                width: 60px;
                            }
                        }
                    }
                }

                &__two-columns {
                    display: flex;
                    width: 100%;
                    margin-bottom: 32px;

                    &__column {
                        display: flex;
                        width: 50%;
                    }
                }
            }
        }
    }

    &__sidebar {
        padding: 32px 20px;

        a, button {
            border: none;
            background-color: transparent;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 16px;
            text-decoration: none;
            color: $color-black;
            padding: 10px 15px;
            border-radius: 8px;

            &:hover {
                background-color: $color-grey-E4E7EC;
            }

            svg {
                width: 24px;
            }

            span {
                margin-left: 8px;
                font-size: 14px;
                font-weight: 600;
                font-family: $font-primary;
            }
        }

        button {
            padding: 7px 15px;

            svg {
                width: 20px;
                margin-left: 2.5px;
            }
        }

        @media only screen and (max-width: 1100px) {
            padding: 32px 60px;
        }
    }
}