/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.notification-container-bottom-right,
.notification-container-mobile-bottom {
    .n-parent {
        height: auto !important;
    }

    button {
        padding: 0;
        font-size: 14px;
        text-decoration: underline;
        color: $color-primary;
        background: transparent;
        border: none;
        cursor: pointer;
    }

    .notification-item {
        display: block;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        cursor: default;

        .notification-content {
            display: flex;
            position: relative;
            padding: 0;
            overflow: hidden;
            background-color: #ffffff;

            p {
                margin: 0;
                font-family: $font-primary;
                font-size: 14px;
                color: #ffffff;
            }

            .notification-content-close {
                position: absolute;
                top: 5px;
                right: 5px;
                padding: 0;

                > svg {
                    width: 15px;
                    height: 15px;

                    path {
                        fill: #ffffff;
                    }
                }
            }

            .notification-content-left {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 42px;

                svg {
                    path {
                        fill: #ffffff;
                    }
                }
            }

            .notification-content-right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 10px;
            }

            &.warning {
                background-color: #e17832;
            }

            &.danger {
                background-color: #da4531;
            }

            &.info {
                background-color: #1792e5;
            }

            &.success {
                background-color: $color-green;
            }
        }
    }
}

.notification-container-mobile-bottom {
    margin-bottom: 0;
}
