/*!
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

.reservation-details-modal {
    .MuiPaper-root {
        background-color: $color-white;
        height: 500px;
        width: 600px;
        border-radius: 18px;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-right: 0;
        background-color: $color-secondary;
        width: 100%;
        height: 70px;
        padding: 0 24px;

        h2 {
            color: $color-green-dark;
            font-weight: bold;
            font-size: 20px;
        }

        button {
            &:hover {
                background-color: transparent;
            }
        }

        svg {
            color: $color-green-dark;
        }
    }

    &__content {
        padding: 20px 30px;
        height: calc(100% - 140px);
        overflow: auto;

        @include screen(mobile) {
            padding: 10px 15px;
        }

        &__info-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            h3 {
                color: $color-green-dark;
                font-weight: bold;
                font-size: 18px;
            }

            p {
                font-size: 16px;
                margin-top: 5px;
            }

            &--row {
                @extend .reservation-details-modal__content__info-wrapper;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                padding-bottom: 10px;
            }

            &--observations {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                max-width: 550px;
                max-height: 100px;
                overflow: auto;
                white-space: normal;
                padding-bottom: 10px;
                margin-top: 5px;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-secondary;
        height: 70px;

        button {
            color: $color-green-dark;
            font-weight: bold;
            text-decoration: underline;
            text-underline-offset: 5px;

            &:hover {
                background-color: transparent;
                text-decoration: underline;
                text-underline-offset: 5px;
            }
        }
    }
}