/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.navbar {
    display: none;
    position: sticky;
    z-index: $nav-z-index;
    top: 0;
    flex-shrink: 0;
    width: 100vw;
    height: 64px;
    background-color: #ffffff;

    @include screen(mobile) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    img {
        max-height: 40px;
        margin-right: $small-gap;
    }
}
