/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.business-menu-items,
.area-menu-items,
.invitations-table-items {
    svg {
        margin-right: 10px;
    }

    a {
        color: #000000;
        text-decoration: none;
    }

    .MuiMenu-paper {
        width: 180px;
        border-radius: 10px;
        padding: 0;

        .MuiMenuItem-root, a {
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: 14px;
            font-weight: 600;

            &:hover {
                background-color: $color-grey-E4E7EC;
                border-radius: 10px;
            }
        }

        .MuiList-padding {
            padding: 4px;
        }
    }
}
