/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.header-container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-bottom: $medium-gap;

    .return-link {
        position: absolute;
        top: 0;
        left: 0;
    }

    img {
        flex: 0;
        margin-bottom: 50px;
        max-height: 44px;
    }

    h2 {
        margin: 0;
    }
}
