/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.profile-screen {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: $medium-gap;

    @include screen(mobile) {
        padding: $small-gap;
    }

    .wide-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 800px;
        height: 100%;

        &__grid-container {
            display: grid;
            grid-auto-rows: 1fr;
            grid-column-gap: $small-gap;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            width: 100%;
            margin-bottom: $small-gap;

            @include screen(mobile) {
                display: flex;
                flex-direction: column;
            }

            .MuiFormControl-root {
                justify-content: flex-end;
            }

            &__double-space {
                grid-column-end: span 2;
            }

            &__double-input {
                display: grid;
                grid-column-gap: $small-gap;
                grid-template-columns: auto 1fr;
                width: 100%;
            }
        }

        &__picture-container {
            display: flex;
            align-items: center;
            width: 100%;

            @include screen(mobile) {
                flex-direction: column;
            }

            img {
                width: 150px;
                height: 150px;
                margin: $small-gap;
                border-radius: 50%;
            }

            p {
                color: $color-dark-grey;
            }
        }
    }
}
