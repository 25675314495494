/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */
.areas-page,
.businesses-page,
.bookings-page,
.merchants-page,
.reservation-managers-page,
.clients-page {
    width: 100%;
    padding: 20px 32px;

    @include screen(mobile) {
        padding: $small-gap;
    }

    h2 {
        font-weight: bold;
    }

    .MuiPaper-elevation1 {
        box-shadow: none;

        .MuiPaper-rounded{
            border: 1px solid $color-grey-E0E0E0;
        }
    }

    .observations {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba($color-grey-D5D7DB, 0.47);
        padding-bottom: 27px;
        margin-bottom: 24px;
        width: 100%;

        a {
            text-decoration: none;
        }

        @include screen(mobile) {
            flex-direction: column;
        }

        &__button-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: $small-gap;

            @include screen(mobile) {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                margin-bottom: $small-gap;
            }
        }
    }

    &__filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: $large-gap;
        margin-bottom: 5px;
        min-height: 55px;

        @include screen(mobile) {
            flex-direction: column;
        }

        &__view-select {
            min-width: 210px;
        }

        &__input-group {
            display: flex;
            width: 100%;
            column-gap: $small-gap;

            @include screen(mobile) {
                flex-direction: column;
            }

            .picker-container {
                display: flex;
                width: 100%;
                max-width: 250px;

                @include screen(mobile) {
                    max-width: 100%;
                }

                .MuiInputBase-root, input {
                    cursor: pointer;
                }

                .MuiFormControl-marginNormal {
                    width: 100%;
                    margin: 0;
                }

                &__clear-icon {
                    align-self: flex-end;
                    margin-bottom: 7px;
                    cursor: pointer;
                    opacity: 0.6;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        .MuiFormControl-root {
            width: 100%;
            max-width: 250px;

            @include screen(mobile) {
                max-width: 100%;
            }
        }

        &__table-buttons {
            display: inline-flex;
            margin-bottom: 20px;

            button {
                all: unset;
                cursor: pointer;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                font-size: 16px;
                font-weight: 600;
                padding: 14px 24px;
                border: 1px solid $color-grey-D5D7DB;
                background-color: $color-white-F2F4F7;
                color: $color-dark-blue-101828;
                transition: all 0.3s ease-in-out;

                &.selected {
                    background-color: $color-white;
                }

                &:last-of-type {
                    border-radius: 0 8px 8px 0;
                    border-left: none;
                }
            }
        }

        &__table-button {
            display: inline-flex;
            margin-bottom: 20px;

            button {
                all: unset;
                cursor: pointer;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 600;
                padding: 14px 24px;
                border: 1px solid $color-grey-D5D7DB;
                background-color: $color-white;
                color: $color-dark-blue-101828;
            }
        }

        .search-field {
            margin-top: 16px;
        }

        .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: none;
        }

        .MuiInputBase-root {
            border-radius: 8px;
            border: 1px solid $color-grey-D5D7DB;
            padding: 5px 12px;
            font-size: 14px;
            font-weight: 600;
        }

        .MuiSelect-select:focus {
            background-color: transparent;
        }

        .MuiSelect-selectMenu {
            padding: 10px 0;
            width: 150px;
        }

        .MuiInput-input {
            padding: 10px 0;
        }

        .MuiFormLabel-root {
            margin-left: 12px;
            margin-top: 8px;
            font-size: 14px;
            font-weight: 600;
            color: $color-grey-767A80;

            &.MuiInputLabel-shrink {
                margin-left: 0;
                margin-bottom: 20px;
                top: -5px;
            }

            &.Mui-focused {
                color: $color-grey-767A80;
            }
        }

        .MuiIconButton-root {
            padding: 0;
        }

        .MuiSvgIcon-root {
            fill: $color-black;
        }
    }

    .MuiPaper-root {
        margin-left: 1px;
        padding-right: 1px;
    }

    .MuiPaper-rounded {
        font-weight: 600;
    }

    .MuiTableCell-head, .MuiTableCell-root {
        font-weight: 600;
    }

    .MuiTableCell-head {
        font-size: 12px;
        color: $color-grey-98A2B3;
    }

    .MuiSelect-icon {
        margin-right: 10px;
    }
}

.table-row {
    &--disabled {
        td {
            color: $color-grey;

            svg {
                color: #000000;
            }
        }
    }
}

.table-cell-status {
    display: flex;
    align-items: center;
    padding: 5px 8px;
    border-radius: 4px;
    width: fit-content;
    font-size: 14px;
    font-weight: 600;

    svg {
        margin-right: 4px;
        min-width: 18px;
    }

    &.reserved {
        background-color: $color-green-D1EECC;
        color: $color-green-009300;
    }

    &.canceled {
        background-color: $color-red-FFD8D9;
        color: $color-red-AF272B;
    }

    &.checked {
        background-color: $color-black-1D2939;
        color: $color-white;
    }

    &.pending {
        background-color: $color-orange;
        color: $color-brown;
    }

    &.absence {
        background-color: $color-grey-E4E7EC;
        color: $color-grey-767A80;
    }
}

.pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    button {
        display: flex;
        align-items: center;
        font-weight: 600;
        border: none;
        background-color: transparent;
        cursor: pointer;

        &:disabled {
            cursor: unset;
        }
    }

    .MuiPaginationItem-root {
        font-size: 14px;
        font-weight: 600;
    }

    .MuiPaginationItem-page.Mui-selected {
        background-color: $color-primary;
        color: $color-white;
    }
}
